











































import {Component, Prop, Mixins} from 'vue-property-decorator';
import moment from 'moment';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import CommentEdit from '@/components/modals/CommentEdit.vue';
import NoContent from '@/components/NoContent.vue';
import AutosuggestModal from '@/components/modals/AutosuggestModal.vue';
import maxCommentLength from '@/constants/maxCommentLength';
import urlRegex from '@/constants/urlRegex';
import CommentMentionsMixin from '@/mixins/CommentMentionsMixin';
import CommentInterface from '@/types/CommentInterface';
import clickInside from '@/utils/clickInside';
import clickOutside from '@/utils/clickOutside';

@Component({
  name: 'Comments',
  components: {
    Loader,
    CommentEdit,
    NoContent,
    AutosuggestModal,
  },
  directives: {
    clickInside,
    clickOutside,
  },
})
export default class Comments extends Mixins(CommentMentionsMixin) {
  @Prop({required: true}) readonly postId!: string;
  @Prop({default: ''}) readonly commentId!: string;
  @Prop({default: false}) readonly showSubcomments!: boolean;

  inputId = `${this.showSubcomments ? 'subcomment-input' : 'comment-input'}`;
  loading = true;
  comments = [] as CommentInterface[];
  editingComment = false;
  idOfCommentToEdit = '';
  indexOfCommentToEdit = null as null | number;
  textToEdit = '';
  isSubcommentsModalActive = false;
  idToShowSubcomments = '';
  indexToShowSubcomments = -1;

  get title(): string {
    if (this.showSubcomments) {
      return this.comments.length ? `All subcomments (${this.comments.length})` : 'Subcomments';
    } else {
      return this.comments.length ? `All comments (${this.comments.length})` : 'Comments';
    }
  }
  get userAvatar(): string {
    if (vxm.user.data.mediaId) {
      const link = vxm.user.data.mediaId.link;
      return link.medium || link.origin || require('@/assets/icons/avatar-default.svg');
    }
    return require('@/assets/icons/avatar-default.svg');
  }
  get noCommentsMessage(): string {
    return this.showSubcomments
      ? 'This comment has no subcomments...\nWant to add the first one?'
      : 'This post has no comments...\nWant to add the first one?';
  }
  get inputIdComputed(): string {
    return this.showSubcomments ? 'subcomment-input' : 'comment-input';
  }
  set inputIdComputed(val: string) {
    this.inputId = val;
  }

  created() {
    if (this.showSubcomments) {
      this.getSubcomments();
    } else {
      this.getComments();
    }
  }

  getComments(): void {
    vxm.post
      .getComments(this.postId)
      .then((res: {data: {data: []}}) => {
        this.comments = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  getSubcomments(): void {
    if (!this.commentId) {
      this.closeComments();
    }
    vxm.post
      .getSubcomments({postId: this.postId, parentId: this.commentId})
      .then((res: {data: {data: []}}) => {
        this.comments = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  onCommentSubmit(): void {
    if (!this.comment || this.comment.length > maxCommentLength) {
      return;
    }
    this.commentError = false;
    this.loading = true;
    if (this.showSubcomments) {
      this.addSubcomment();
    } else {
      this.addComment();
    }
  }
  addComment(): void {
    vxm.post
      .addComment({
        postId: this.postId,
        text: this.comment,
        ...(this.mentionsArray.length && {mentions: this.mentionsArray.map((item) => item._id)}),
      })
      .then(async () => {
        await this.getComments();
        await this.onInput();
        this.$emit('commentAdded');
      })
      .finally(() => {
        this.loading = false;
      });
  }
  addSubcomment(): void {
    vxm.post
      .addSubcomment({
        postId: this.postId,
        text: this.comment,
        parentId: this.commentId,
        mainId: this.commentId,
        ...(this.mentionsArray.length && {mentions: this.mentionsArray.map((item) => item._id)}),
      })
      .then(async () => {
        await this.getSubcomments();
        await this.onInput();
        this.$emit('subcommentAdded');
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeComments(): void {
    if (this.showSubcomments) {
      this.$emit('closeSubcomments');
    } else {
      this.$emit('closeComments');
    }
  }
  formatDate(index: number): string {
    return moment(this.comments[index].createdAt).fromNow();
  }
  getAvatar(index: number): string {
    return this.comments[index].user.mediaId
      ? this.comments[index].user.mediaId.link.medium || this.comments[index].user.mediaId.link.origin
      : require('@/assets/icons/avatar-default.svg');
  }
  onCommentLike(index: number): void {
    const commentToLike = this.comments[index];
    commentToLike.isLiked = !commentToLike.isLiked;
    commentToLike.likesCount += commentToLike.isLiked ? 1 : -1;
    this.comments.splice(index, 1, commentToLike);
    const objectType = this.showSubcomments ? 'subComment' : 'comment';
    vxm.post.likeComment({objectId: this.comments[index]._id, objectType: objectType}).catch(() => {
      this.comments[index].isLiked = !this.comments[index].isLiked;
      commentToLike.likesCount += commentToLike.isLiked ? 1 : -1;
      this.comments.splice(index, 1, commentToLike);
    });
  }
  isOwnComment(index: number): boolean {
    return this.comments[index].userId === vxm.user.data._id;
  }
  onCommentEdit(index: number): void {
    const commentToEdit = this.comments[index];
    if (commentToEdit.userId !== vxm.user.data._id) {
      return;
    }
    this.idOfCommentToEdit = commentToEdit._id;
    this.textToEdit = commentToEdit.text;
    this.editingComment = true;
    this.indexOfCommentToEdit = index;
  }
  onCloseEditModal(): void {
    this.editingComment = false;
    this.textToEdit = '';
    this.idOfCommentToEdit = '';
    this.indexOfCommentToEdit = null;
  }
  onCommentUpdated(updatedComment: {
    id: string;
    text: string;
    mentions: {username: string; _id: string}[] | undefined;
  }): void {
    if (this.indexOfCommentToEdit !== null && this.comments[this.indexOfCommentToEdit]._id === updatedComment.id) {
      const comment = this.comments[this.indexOfCommentToEdit];
      comment.text = updatedComment.text;
      comment.mentions = updatedComment.mentions || [];
      this.comments.splice(this.indexOfCommentToEdit, 1, comment);
      this.indexOfCommentToEdit = null;
    }
    this.onCloseEditModal();
  }
  onCommentDeleted(): void {
    if (this.indexOfCommentToEdit !== null) {
      this.comments.splice(this.indexOfCommentToEdit, 1);
      if (this.showSubcomments) {
        this.$emit('onSubcommentDelete');
      } else {
        this.$emit('commentDeleted');
      }
      this.indexOfCommentToEdit = null;
    }
    this.onCloseEditModal();
  }
  showSubcommentsModal(id: string, index: number): void {
    this.idToShowSubcomments = id;
    this.isSubcommentsModalActive = true;
    this.indexToShowSubcomments = index;
  }
  onCloseSubcomments(): void {
    this.isSubcommentsModalActive = false;
    this.idToShowSubcomments = '';
    this.indexToShowSubcomments = -1;
  }
  onSubcommentAdded(): void {
    if (this.indexToShowSubcomments >= 0 && this.indexToShowSubcomments < this.comments.length) {
      this.comments[this.indexToShowSubcomments].subCommentsCount += 1;
      this.indexToShowSubcomments = -1;
    }
  }
  onSubcommentDeleted(): void {
    if (this.indexToShowSubcomments >= 0 && this.indexToShowSubcomments < this.comments.length) {
      this.comments[this.indexToShowSubcomments].subCommentsCount -= 1;
      this.indexToShowSubcomments = -1;
    }
  }
  convertTextToHtml(index: number): string {
    return this.convertMentionsToLinks(this.convertUrlsToLinks(this.comments[index].text), index);
  }
  convertUrlsToLinks(text: string): string {
    return text.replace(urlRegex, '<a target="_blank" rel="noopener" href="$1">$1</a>');
  }
  convertMentionsToLinks(text: string, index: number): string {
    let convertedString = text;
    const mentions = this.comments[index].mentions;
    if (mentions && mentions.length) {
      mentions.forEach((mention) => {
        const linkToProfile = `${window.location.origin}/${mention.username}`;
        convertedString = convertedString.replaceAll(
          `@${mention.username}`,
          `<a target="_blank" rel="noopener" href="${linkToProfile}">@${mention.username}</a>`,
        );
      });
    }
    return convertedString;
  }
}
