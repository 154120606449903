





import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class JWPlayer extends Vue {
  @Prop(String) readonly image!: string;
  @Prop({default: '100%'}) readonly width!: string | number;
  @Prop({default: 200}) readonly height!: string | number;
  @Prop(String) readonly path!: string;
  @Prop({default: false}) readonly loop!: boolean;
  @Prop({type: Boolean, default: true}) readonly ratioByVideo!: boolean;
  jwPlayer = null as any;

  @Watch('path')
  onPathChange() {
    this.initPlayer();
  }

  mounted() {
    this.jwPlayer = window.jwplayer(this.$el.firstChild as HTMLElement);
    this.initPlayer();
  }

  beforeDestroy() {
    this.jwPlayer.stop();
    this.jwPlayer.remove();
    this.jwPlayer = null;
  }

  initPlayer() {
    this.jwPlayer.setup({
      file: this.path,
      image: this.image || undefined,
      height: this.height,
      width: this.width,
      mute: true,
      repeat: this.loop,
    });
    if (this.ratioByVideo) {
      this.jwPlayer.on('meta', (meta: {height: number; width: number}) => {
        this.jwPlayer.setConfig({aspectratio: `${meta.width}:${meta.height}`});
      });
    }
  }
}
