










import {Component, Mixins, Prop} from 'vue-property-decorator';
import CreateAndEditPost from '@/views/CreateAndEditPost.vue';
import clickInside from '@/utils/clickInside';
import Post from '@/components/Post.vue';
import PostInterface from '@/types/PostInterface';
import PostAuthorInterface from '@/types/PostAuthorInterface';
import {vxm} from '@/store';
import NoScrollMixin from '@/mixins/NoScrollMixin';

@Component({
  components: {Post, CreateAndEditPost},
  directives: {
    clickInside,
  },
})
export default class NewPost extends Mixins(NoScrollMixin) {
  @Prop({type: Object}) readonly post!: PostInterface;
  @Prop(Object) readonly author!: PostAuthorInterface | null;
  @Prop(String) readonly postId!: string;
  currentPost: PostInterface | null = null;
  currentAuthor: PostAuthorInterface | null = null;

  mounted() {
    if (this.author) {
      this.currentAuthor = this.author;
    }
    if (this.post) {
      this.currentPost = this.post;
    } else if (this.postId) {
      vxm.post.getPost(this.postId).then((res: {data: {data: PostInterface; message: string}}) => {
        this.currentPost = res.data.data;
      });
    }
  }

  closeModal(): void {
    this.$emit('closeModal');
  }
}
