


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {vxm} from '@/store';
import Post from '@/components/Post.vue';
import PostAuthorInterface from '@/types/PostAuthorInterface';
import PostInterface from '@/types/PostInterface';
import debounce from '@/utils/debounce';
import PostModal from '@/components/modals/PostModal.vue';

@Component({
  name: 'Feed',
  components: {PostModal, Post},
  computed: {
    ...mapState('user', ['viewAsGrid']),
  },
})
export default class Feed extends Vue {
  @Prop({type: Array, required: true}) readonly feed!: PostInterface[];
  @Prop({type: String, default: 'All posts'}) readonly title!: string;
  @Prop({type: Boolean, default: false}) readonly isTheOnlyAuthor!: boolean;
  @Prop({type: Boolean, default: false}) readonly showBackBtn!: boolean;
  @Prop({
    type: Object,
    default() {
      return {
        name: '',
        username: '',
        avatar: '',
      };
    },
  })
  readonly author!: PostAuthorInterface;
  @Prop({type: Boolean, default: true}) readonly showTitle!: boolean;

  gridColumnOne = [] as PostInterface[];
  gridColumnTwo = [] as PostInterface[];
  gridColumnThree = [] as PostInterface[];
  viewAsGrid!: boolean;
  columns = 0;
  showPostModal = false;
  selectedPost: PostInterface | null = null;

  debounceHandler = () => {
    /* Will be replaced */
  };

  get postAuthor(): PostAuthorInterface | null {
    if (this.isTheOnlyAuthor) {
      return this.author;
    }
    return null;
  }

  @Watch('viewAsGrid')
  handler() {
    if (this.viewAsGrid) {
      this.onGridView(false);
    }
  }
  @Watch('feed.length')
  onFeedLengthChange() {
    if (this.viewAsGrid) {
      this.onGridView(true);
    }
  }

  mounted() {
    this.debounceHandler = debounce(this.onGridView, 300);
    window.addEventListener('resize', this.debounceHandler, false);
    this.onGridView(false);
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceHandler, false);
  }
  openPost(post: PostInterface) {
    this.showPostModal = true;
    this.selectedPost = post;
  }
  onGridView(growGrid: boolean) {
    const isMobile = window.innerWidth < 650;
    const cols = isMobile ? 2 : 3;
    if (growGrid) {
      if (!this.viewAsGrid) {
        return;
      }
    } else if (!this.viewAsGrid || cols === this.columns) {
      return;
    }
    this.columns = cols;
    this.gridColumnOne = [];
    this.gridColumnTwo = [];
    this.gridColumnThree = [];
    let j = 0;
    for (let i = 0; i < this.feed.length; i += 1) {
      const post = this.feed[i];
      switch (j % cols) {
        case 0: {
          this.gridColumnOne.push(post);
          j += 1;
          break;
        }
        case 1: {
          this.gridColumnTwo.push(post);
          j += 1;
          break;
        }
        case 2: {
          this.gridColumnThree.push(post);
          j += 1;
          break;
        }
        default:
      }
    }
  }
  switchView(): void {
    vxm.user.switchView();
  }
  onPostAction(payload: {id: string; action: string}): void {
    this.$emit('postAction', payload);
    if (payload.action === 'delete') {
      this.columns = 0;
      if (this.viewAsGrid) {
        this.onGridView(false);
      }
    }
  }
  onPostUpdated(updatedPost: PostInterface): void {
    this.$emit('postUpdated', updatedPost);
    this.columns = 0;
    if (this.viewAsGrid) {
      this.onGridView(false);
    }
  }
  onPostReposted(): void {
    this.$emit('postReposted');
  }
  onPostReported(id: string): void {
    this.onPostAction({id, action: 'delete'});
  }
}
