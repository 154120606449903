













































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import {vxm} from '@/store';
import Comments from '@/components/modals/Comments.vue';
import JWPlayer from '@/components/JWPlayer.vue';
import Loader from '@/components/Loader.vue';
import Confirmation from '@/components/modals/Confirmation.vue';
import NoContent from '@/components/NoContent.vue';
import ReportPostModal from '@/components/modals/ReportPostModal.vue';
import ProfileActivityPreferencesModal from '@/components/modals/ProfileActivityPreferencesModal.vue';
import CreateAndEditPost from '@/views/CreateAndEditPost.vue';
import {MediaTypes} from '@/constants/mediaTypes';
import urlRegex from '@/constants/urlRegex';
import PostAuthorInterface from '@/types/PostAuthorInterface';
import PostInterface from '@/types/PostInterface';
import clickInside from '@/utils/clickInside';

@Component({
  name: 'Post',
  components: {
    CreateAndEditPost,
    JWPlayer,
    CoolLightBox,
    Comments,
    Loader,
    Confirmation,
    NoContent,
    ReportPostModal,
    ProfileActivityPreferencesModal,
  },
  directives: {clickInside},
})
export default class Post extends Vue {
  @Prop({type: Boolean, required: true}) readonly viewAsGrid!: boolean;
  @Prop({type: Object, required: true}) readonly post!: PostInterface;
  @Prop(Object) readonly author!: PostAuthorInterface | null;
  @Prop({default: false}) readonly inModal!: boolean;

  loading = false;
  MediaTypes = MediaTypes;
  viewerItems = [] as {src: string; thumb?: string; autoplay?: boolean}[];
  viewerIndex = null as null | number;
  showComments = false;
  postId = '';
  showEditMenu = false;
  editingPost = false;
  deletingPost = false;
  showReportModal = false;
  showProfileActivityPreferencesModal = false;

  get authorAvatar(): string {
    if (this.author !== null) {
      return this.author.avatar;
    }
    return this.post.user.mediaId && this.post.user.mediaId.link
      ? this.post.user.mediaId.link.medium || this.post.user.mediaId.link.origin
      : require('@/assets/icons/avatar-default.svg');
  }

  get authorName(): string {
    return this.author !== null ? this.author.name : this.post.user.name;
  }

  get formattedPostDate(): string {
    return moment(this.post.createdAt).fromNow();
  }

  get createdAt(): string {
    return moment(this.post.createdAt).format('MMM DD, YYYY, h:MM:SS a');
  }

  get username(): string {
    return this.post.user.username;
  }

  get isOwnPost(): boolean {
    return this.post.user._id === vxm.user.data._id;
  }

  get parentPostAuthorName(): string {
    return this.post.parentPost ? this.post.parentPost.user.name : '';
  }

  get parentPostUsername(): string {
    return this.post.parentPost ? this.post.parentPost.user.username : '';
  }

  get formattedParentPostDate(): string {
    return this.post.parentPost ? moment(this.post.parentPost.createdAt).fromNow() : '';
  }

  get parentPostAuthorAvatar(): string {
    return this.post.parentPost && this.post.parentPost.user.mediaId && this.post.parentPost.user.mediaId.link
      ? this.post.parentPost.user.mediaId.link.medium || this.post.parentPost.user.mediaId.link.origin
      : require('@/assets/icons/avatar-default.svg');
  }

  get noContentMessage(): string {
    return 'The original post has been deleted...';
  }

  @Watch('showComments')
  onShowCommentsChange() {
    document.body.style.overflow = this.showComments ? 'hidden' : 'visible';
  }

  @Watch('editingPost')
  onEditingPostChange() {
    document.body.style.overflow = this.editingPost ? 'hidden' : 'visible';
  }

  @Watch('showReportModal')
  onShowReportModalChange() {
    document.body.style.overflow = this.showReportModal ? 'hidden' : 'visible';
  }

  @Watch('post', {deep: true})
  onPostChanged() {
    this.initPost();
  }

  created() {
    this.initPost();
  }

  beforeDestroy() {
    if (this.showComments || this.editingPost || this.showReportModal) {
      document.body.style.overflow = 'visible';
    }
  }

  async initPost() {
    if (this.post.media.length) {
      const array = [] as {src: string; thumb?: string; autoplay?: boolean}[];
      this.post.media.forEach((item) => {
        array.push({
          src: item.link.origin || item.link.large,
          thumb: item.link.medium || undefined,
          autoplay: item.type === MediaTypes.Video ? true : undefined,
        });
      });
      this.viewerItems = array;
    } else if (this.post.parentPost && this.post.parentPost.media.length) {
      const array = [] as {src: string; thumb?: string; autoplay?: boolean}[];
      this.post.parentPost.media.forEach((item) => {
        array.push({
          src: item.link.origin || item.link.large,
          thumb: item.link.medium || undefined,
          autoplay: item.type === MediaTypes.Video ? true : undefined,
        });
      });
      this.viewerItems = array;
    }
  }

  onPostLike(): void {
    this.$emit('postAction', {id: this.post._id, action: 'like'});
    vxm.post.like({objectId: this.post._id, objectType: 'post'}).catch(() => {
      this.$emit('postAction', {id: this.post._id, action: 'like'});
    });
  }

  onPostFavor(): void {
    this.$emit('postAction', {id: this.post._id, action: 'favor'});
    if (!this.post.isFavorited) {
      vxm.post.disfavor({postId: this.post._id}).catch(() => {
        this.$emit('postAction', {id: this.post._id, action: 'favor'});
      });
    } else {
      vxm.post.favor({postId: this.post._id}).catch(() => {
        this.$emit('postAction', {id: this.post._id, action: 'favor'});
      });
    }
  }

  deletePost(): void {
    this.loading = true;
    vxm.post
      .deletePost(this.post._id)
      .then(() => {
        this.$emit('postAction', {id: this.post._id, action: 'delete'});
        this.deletingPost = false;
        this.closeEditMenu();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onViewImages(): void {
    this.viewerIndex = 0;
  }

  onViewVideo(event: MouseEvent): void {
    if (this.viewAsGrid && !this.inModal) {
      this.$emit('open');
      event.preventDefault();
    }
  }

  onShowComments(): void {
    this.postId = this.post._id;
    this.showComments = true;
  }

  onCommentAdded(): void {
    this.post.commentsCount += 1;
  }

  onCommentDeleted(): void {
    this.post.commentsCount -= 1;
  }

  onCloseComments(): void {
    this.showComments = false;
    this.postId = '';
  }

  showPostsOnHashtag(hashtag: string): void {
    if (hashtag) {
      this.$router.push({name: 'popular', params: {token: hashtag}});
    }
  }

  onPostDeleting(): void {
    if (!this.isOwnPost) {
      return;
    }
    this.deletingPost = true;
  }

  onDeletePostCancelled(): void {
    this.deletingPost = false;
    this.closeEditMenu();
  }

  editPost() {
    if (!this.isOwnPost) {
      return;
    }
    this.closeEditMenu();
    this.editingPost = true;
  }

  onPostEditingCanceled(): void {
    this.editingPost = false;
  }

  onPostUpdated(updatedPost: PostInterface): void {
    this.editingPost = false;
    this.$emit('postUpdated', updatedPost);
  }

  repost(id: string): void {
    if (!id) {
      return;
    }
    if (vxm.user.postPreferencesUnset) {
      this.showProfileActivityPreferencesModal = true;
      return;
    }
    this.loading = true;
    vxm.post
      .repost(id)
      .then(() => {
        this.$toasted.show('Post is successfully reposted.', {
          className: 'toasted-info',
        });
        this.$emit('postReposted');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  closeEditMenu(): void {
    this.showEditMenu = false;
  }

  onPostReporting(): void {
    if (this.isOwnPost) {
      return;
    }
    this.showReportModal = true;
    this.closeEditMenu();
  }

  onPostReported(postId: string): void {
    if (postId === this.post._id) {
      this.$emit('postReported', postId);
    }
  }

  convertTextToHtml(text: string, parentPost: boolean): string {
    return this.convertMentionsToLinks(this.convertUrlsToLinks(text), parentPost);
  }

  convertUrlsToLinks(text: string): string {
    return text.replace(urlRegex, '<a target="_blank" rel="noopener" href="$1">$1</a>');
  }

  convertMentionsToLinks(text: string, parentPost: boolean): string {
    let convertedString = text;
    const mentions = parentPost ? this.post.parentPost?.mentions : this.post.mentions;
    if (mentions && mentions.length) {
      mentions.forEach((mention) => {
        const linkToProfile = `${window.location.origin}/${mention.username}`;
        convertedString = convertedString.replaceAll(
          `@${mention.username}`,
          `<a target="_blank" rel="noopener" href="${linkToProfile}">@${mention.username}</a>`,
        );
      });
    }
    return convertedString;
  }

  copyLink() {
    navigator.clipboard.writeText(window.location.origin + '/' + this.post.user.username + '/' + this.post._id);
    this.showEditMenu = false;
    this.$toasted.show('Link was copied to clipboard', {
      className: 'toasted-info',
    });
  }

  closeProfileActivityPreferencesModal(): void {
    this.showProfileActivityPreferencesModal = false;
  }

  onUserUpdated(): void {
    this.showProfileActivityPreferencesModal = false;
    if (vxm.user.postPreferencesUnset) {
      return;
    }
    this.repost(this.post._id);
  }

  onMediaViewerOpen() {
    const isMobile = window.innerWidth < 650;
    if (!isMobile) {
      return;
    }
    if (this.post.media.length && this.post.media[0].type === MediaTypes.Video) {
      const videos = this.$el.getElementsByClassName('cool-lightbox-video');
      if (videos.length) {
        const video = videos[0] as any;
        if (this.enterFullscreen(video)) {
          this.addVideoOnExitFullscreenOnMobile(video);
        }
      }
    }
  }

  enterFullscreen(video: any): boolean {
    let fullscreenVideoPreview = true;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitrequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.mozRequestFullscreen) {
      video.mozRequestFullScreen();
    } else {
      fullscreenVideoPreview = false;
    }
    return fullscreenVideoPreview;
  }

  addVideoOnExitFullscreenOnMobile(video: Element): void {
    video.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        const buttons = this.$el.getElementsByClassName('cool-lightbox-toolbar__btn') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < buttons.length; i += 1) {
          if (buttons[i].getAttribute('title') === 'Close') {
            buttons[i].click();
            break;
          }
        }
      }
    });
  }
}
