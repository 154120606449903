import {Component, Prop, Vue} from 'vue-property-decorator';
import mentionQuantityUploadLimit from '@/constants/mentionQuantityUploadLimit';
import {SuggestionTypes} from '@/constants/suggestionTypes';
import maxCommentLength from '@/constants/maxCommentLength';
import mentionValidationRegex from '@/constants/mentionValidationRegex';
import debounce from '@/utils/debounce';

@Component
export default class CommentMentionsMixin extends Vue {
  @Prop({default: false}) readonly showSubcomments!: boolean;

  inputId = '';
  comment = '';
  commentError = false;
  commentErrorMessage = '';
  commentErrorList = {
    CommentLengthError: `${
      this.showSubcomments ? 'Subcomment' : 'Comment'
    } should be up to ${maxCommentLength} symbols long.`,
  };
  showSuggestions = false;
  suggestionQuery = '';
  SuggestionTypes = SuggestionTypes;
  pickFirstSuggestion = false;
  focusedSuggestion = 0;
  mentions = new Map() as Map<string, {username: string; _id: string}>;
  mentionsArray = [] as {username: string; _id: string}[];
  mentionErrorList = {
    MentionQuantityError: `Maximum of ${mentionQuantityUploadLimit} mentions is reached. Last mention wasn't added.
    Delete mentions to free space for the new ones.`,
    MentionValidationError: 'Mentions should be separated by spaces.',
  };
  suggestDebounceHandler = () => {
    /* Will be replaced */
  };

  mounted() {
    this.suggestDebounceHandler = debounce(this.onInputTyping, 300);
    window.addEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.onArrowNavigation(1);
      } else if (e.code === 'ArrowUp') {
        this.onArrowNavigation(-1);
      }
    });
  }
  beforeDestroy() {
    window.removeEventListener('keyup', (e) => {
      this.$nextTick();
      if (e.code === 'ArrowDown') {
        this.onArrowNavigation(1);
      } else if (e.code === 'ArrowUp') {
        this.onArrowNavigation(-1);
      }
    });
  }

  async onInput(value?: string) {
    const el = document.getElementById(this.inputId) as HTMLInputElement;
    this.comment = value || '';
    if (this.comment.length > maxCommentLength) {
      this.commentErrorMessage = this.commentErrorList.CommentLengthError;
      this.commentError = true;
      this.comment = this.comment.slice(0, maxCommentLength);
    } else {
      this.commentError = false;
    }
    await this.$nextTick();
    if (el) {
      el.style.height = '';
      el.style.height = `${el.scrollHeight}px`;
      el.blur();
      el.focus();
    }
    this.suggestDebounceHandler();
  }
  onAutosuggestBlur(): void {
    this.showSuggestions = false;
    const modal = document.getElementById(this.inputId);
    if (modal) {
      modal.scrollTop = 0;
    }
  }
  async setMentionBySuggestion(suggestion: {username: string; _id: string}) {
    if (!Object.values(suggestion).length || !suggestion._id) {
      return;
    }
    const el = document.getElementById(this.inputId) as HTMLInputElement;
    const cursorPosition = el.selectionStart as number;
    const commentBeforeCursor = this.comment.slice(0, cursorPosition);
    const lastAt = commentBeforeCursor.lastIndexOf('@');
    const unchanged = commentBeforeCursor.slice(0, lastAt);
    const newCommentBeforeCursor = `${unchanged}@${suggestion.username}`;
    const newCursorPosition = cursorPosition + (newCommentBeforeCursor.length - commentBeforeCursor.length);
    this.comment = this.comment.replace(commentBeforeCursor, newCommentBeforeCursor);
    this.setMentions(suggestion);
    this.showSuggestions = false;
    this.suggestionQuery = '';
    this.pickFirstSuggestion = false;
    await this.$nextTick();
    el.focus();
    el.setSelectionRange(newCursorPosition, newCursorPosition);
  }
  setMentions(suggestion: {username: string; _id: string}): void {
    this.commentError = false;
    if (!this.mentions.has(suggestion._id) && this.mentions.size >= mentionQuantityUploadLimit) {
      this.$toasted.show(this.mentionErrorList.MentionQuantityError, {
        className: 'toasted-error',
      });
      return;
    }
    this.mentions.set(suggestion._id, suggestion);
    this.mentionsArray = Array.from(this.mentions.values());
  }
  onInputTyping(): void {
    if (this.comment.length < 2) {
      this.onAutosuggestBlur();
      return;
    }
    const el = document.getElementById(this.inputId) as HTMLInputElement;
    const commentBeforeCursor = this.comment.slice(0, el.selectionStart as number);
    const lastSpace = commentBeforeCursor.lastIndexOf(' ');
    const lastAt = commentBeforeCursor.lastIndexOf('@');
    if (lastAt !== -1 && lastAt > lastSpace) {
      const suggestionQuery = commentBeforeCursor.slice(lastAt + 1);
      if (!/\s+/.test(suggestionQuery)) {
        this.suggestionQuery = suggestionQuery;
      }
    } else {
      this.onAutosuggestBlur();
    }
  }
  onArrowNavigation(changeBy: number) {
    const input = document.getElementById(this.inputId) as HTMLInputElement;
    const suggestions = this.$el.getElementsByClassName('autosuggest-suggestion') as HTMLCollectionOf<HTMLElement>;
    if (changeBy === 1) {
      if (document.activeElement === input) {
        if (this.showSuggestions) {
          this.focusedSuggestion = 0;
          suggestions[this.focusedSuggestion].focus();
        } else {
          this.showSuggestions = true;
        }
      } else if (this.focusedSuggestion === suggestions.length - 1) {
        input.focus();
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestion += 1)].focus();
      }
      return;
    }
    if (changeBy === -1) {
      if (document.activeElement === input) {
        if (this.showSuggestions) {
          this.focusedSuggestion = suggestions.length - 1;
          suggestions[this.focusedSuggestion].focus();
        } else {
          this.showSuggestions = true;
        }
      } else if (this.focusedSuggestion === 0) {
        input.focus();
        input.setSelectionRange(this.comment.length, this.comment.length);
      } else if (document.activeElement && document.activeElement.classList.contains('autosuggest-suggestion')) {
        suggestions[(this.focusedSuggestion -= 1)].focus();
      }
    }
  }
  onEnterPressed(event: KeyboardEvent): void {
    if (this.showSuggestions) {
      event.preventDefault();
      this.pickFirstSuggestion = true;
    }
  }
  onInputBlur(): void {
    this.commentError = false;
    if (this.mentions.size) {
      this.actualizeMentions();
      this.mentionsArray = Array.from(this.mentions.values());
    }
  }
  actualizeMentions(): void {
    const mentions = this.comment
      .trim()
      .split(/\s+/)
      .filter((word) => mentionValidationRegex.test(word));
    this.mentions.forEach((value, key) => {
      if (mentions.indexOf(`@${value.username}`) === -1) {
        this.mentions.delete(key);
      }
    });
  }
  onHideAutosuggest(): void {
    if (this.showSuggestions) {
      const input = document.getElementById(this.inputId) as HTMLInputElement;
      if (input) {
        input.focus();
      }
      this.showSuggestions = false;
    }
  }
  onEscape(): void {
    if (this.showSuggestions) {
      this.onAutosuggestBlur();
    } else {
      this.onInputBlur();
    }
  }
}
