










import {Component, Prop, Vue} from 'vue-property-decorator';
import clickInside from '@/utils/clickInside';

@Component({
  directives: {
    clickInside,
  },
})
export default class Confirmation extends Vue {
  @Prop({type: String, default: require('@/assets/icons/confirmation-question.svg')}) readonly icon!: string;
  @Prop({type: String, required: true}) readonly question!: string;
  @Prop({type: String, default: 'Cancel'}) readonly cancelText!: string;
  @Prop({type: String, default: 'Confirm'}) readonly confirmText!: string;

  cancelAction(): void {
    this.$emit('cancelAction');
  }
  confirmAction(): void {
    this.$emit('confirmAction');
  }
}
