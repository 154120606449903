





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Vuelidate from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import Loader from '@/components/Loader.vue';
import Confirmation from '@/components/modals/Confirmation.vue';
import BhSelect from '@/components/BhSelect.vue';
import ReportPostTypes from '@/constants/reportPostTypes';
import clickInside from '@/utils/clickInside';

Vue.use(Vuelidate);

@Component({
  name: 'ReportPostModal',
  components: {Loader, Confirmation, BhSelect},
  directives: {
    clickInside,
  },
  validations: {
    form: {
      type: {
        code: {required},
        text: {required},
      },
    },
  },
})
export default class ReportPostModal extends Vue {
  @Prop({type: String, required: true}) readonly postId!: string;
  loading = false;
  form = {
    type: {code: '', text: ''} as {code: string; text: string},
  };
  reportingPost = false;

  get types(): readonly string[] {
    return ReportPostTypes.map((type) => type.text);
  }

  reportPost(): void {
    this.reportingPost = false;
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.loading = true;
    vxm.post
      .reportPost({postId: this.postId, type: this.form.type.code})
      .then(() => {
        this.$emit('postReported', this.postId);
        this.$toasted.show('Post is successfully reported.', {
          className: 'toasted-info',
        });
        this.closeModal();
      })
      .catch((err: {response: {data: {message: string}}}) => {
        const message = err.response.data.message;
        this.$toasted.show(message || 'Error happened while reporting a post.', {
          className: 'toasted-error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeModal(): void {
    this.$emit('closeReportModal');
  }
  onReportClick(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.reportingPost = true;
  }
  onOptionSelected(value: string): void {
    const type = ReportPostTypes.find((type) => type.text === value);
    if (type) {
      this.form.type = type;
    }
  }
}
