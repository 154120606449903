import PostInterface from "@/types/PostInterface";
import { Component, Vue } from "vue-property-decorator";
import debounce from "@/utils/debounce";

@Component
export default class FeedMixin extends Vue {
  feed = [] as PostInterface[];
  friendsFeed = null as PostInterface[] | null;
  generalFeed = null as PostInterface[] | null;
  page = 1;
  limit = 10;
  total = 0;
  complete = false;
  debounceHandler = () => {
    /* Will be replaced */
  };

  created() {
    this.debounceHandler = debounce(this.onScroll, 300);
    window.addEventListener('scroll', this.debounceHandler, false);
    this.getFeed();
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceHandler, false);
  }

  onScroll(): void {
    if (this.complete) {
      return;
    }
    if (this.isPageBottom()) {
      this.getFeed();
    }
  }

  getFeed(): void {
    // real method should be placed in component which uses this mixin.
  }

  onPostAction(payload: {id: string; action: string}) {
    if (!payload.id || !payload.action) {
      return;
    }
    const feedIndex = this.feed.findIndex((post) => post._id === payload.id);
    const friendsFeedIndex = this.friendsFeed ? this.friendsFeed.findIndex((post) => post._id === payload.id) : -1;
    const generalFeedIndex = this.generalFeed ? this.generalFeed.findIndex((post) => post._id === payload.id) : -1;
    if (payload.action === 'like') {
      this.onPostLike(feedIndex, friendsFeedIndex, generalFeedIndex);
    } else if (payload.action === 'favor') {
      this.onPostFavor(feedIndex, friendsFeedIndex, generalFeedIndex);
    } else if (payload.action === 'delete') {
      this.onPostDelete(feedIndex, friendsFeedIndex, generalFeedIndex);
    }
  }

  onPostLike(feedIndex: number, friendsFeedIndex: number, generalFeedIndex: number): void {
    if (feedIndex !== -1) {
      const post = this.feed[feedIndex];
      post.isLiked = !post.isLiked;
      post.likesCount += post.isLiked ? 1 : -1;
    }
    if (friendsFeedIndex !== -1) {
      const post = this.friendsFeed![friendsFeedIndex];
      post.isLiked = !post.isLiked;
      post.likesCount += post.isLiked ? 1 : -1;
    }
    if (generalFeedIndex !== -1) {
      const post = this.generalFeed![generalFeedIndex];
      post.isLiked = !post.isLiked;
      post.likesCount += post.isLiked ? 1 : -1;
    }
  }

  onPostFavor(feedIndex: number, friendsFeedIndex: number, generalFeedIndex: number): void {
    if (feedIndex !== -1) {
      this.feed[feedIndex].isFavorited = !this.feed[feedIndex].isFavorited;
    }
    if (friendsFeedIndex !== -1) {
      this.friendsFeed![friendsFeedIndex].isFavorited = !this.friendsFeed![friendsFeedIndex].isFavorited;
    }
    if (generalFeedIndex !== -1) {
      this.generalFeed![generalFeedIndex].isFavorited = !this.generalFeed![generalFeedIndex].isFavorited;
    }
  }

  onPostDelete(feedIndex: number, friendsFeedIndex: number, generalFeedIndex: number): void {
    if (feedIndex !== -1) {
      this.feed.splice(feedIndex, 1);
    }
    if (friendsFeedIndex !== -1) {
      this.friendsFeed!.splice(friendsFeedIndex, 1);
    }
    if (generalFeedIndex !== -1) {
      this.generalFeed!.splice(generalFeedIndex, 1);
    }
  }

  onPostUpdated(updatedPost: PostInterface): void {
    if (!updatedPost || !updatedPost._id) {
      return;
    }
    let index = this.feed.findIndex((post) => post._id === updatedPost._id);
    if (index !== -1) {
      this.feed.splice(index, 1, updatedPost);
    }
    if (this.friendsFeed && this.friendsFeed.length) {
      index = this.friendsFeed.findIndex((post) => post._id === updatedPost._id);
      if (index !== -1) {
        this.friendsFeed.splice(index, 1, updatedPost);
      }
    }
    if (this.generalFeed && this.generalFeed.length) {
      index = this.generalFeed.findIndex((post) => post._id === updatedPost._id);
      if (index !== -1) {
        this.generalFeed.splice(index, 1, updatedPost);
      }
    }
  }

  isPageBottom(): boolean {
    const bottomLoaderHeight = this.getBottomLoaderHeight();
    return (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - bottomLoaderHeight
    );
  }

  getBottomLoaderHeight(): number {
    const bottomLoader = document.getElementById('bottom-loader');
    return bottomLoader ? bottomLoader.clientHeight : 0;
  }

  async onFeedGrow() {
    if (this.limit * this.page >= this.total) {
      this.complete = true;
    }
    this.page += 1;
    await this.$nextTick();
    if (this.isPageBottom() && !this.complete) {
      this.debounceHandler();
    }
  }

  onPostReposted(): void {
    this.$router.go(0);
  }
}
