






















import {Component, Prop, Mixins} from 'vue-property-decorator';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import Confirmation from '@/components/modals/Confirmation.vue';
import AutosuggestModal from '@/components/modals/AutosuggestModal.vue';
import maxCommentLength from '@/constants/maxCommentLength';
import CommentMentionsMixin from '@/mixins/CommentMentionsMixin';
import clickInside from '@/utils/clickInside';
import clickOutside from '@/utils/clickOutside';

@Component({
  name: 'CommentEdit',
  components: {Loader, Confirmation, AutosuggestModal},
  directives: {
    clickInside,
    clickOutside,
  },
})
export default class CommentEdit extends Mixins(CommentMentionsMixin) {
  @Prop({type: String, required: true}) readonly text!: string;
  @Prop({type: String, required: true}) readonly commentId!: string;
  @Prop({default: false}) readonly isSubcomment!: boolean;

  loading = false;
  inputId = 'edit-comment-input';
  deletingComment = false;

  get saveInactive(): boolean {
    return !this.comment || this.comment === this.text;
  }
  get commentType(): string {
    return `${this.isSubcomment ? 'subcomment' : 'comment'}`;
  }
  get question(): string {
    return `Are you sure you want to delete your ${this.commentType}?`;
  }
  get confirmText(): string {
    return `Delete ${this.commentType}`;
  }
  get title(): string {
    return `Edit ${this.commentType}`;
  }

  mounted() {
    this.onInput(this.text);
  }

  onCommentEdited(): void {
    if (!this.comment || this.comment.length > maxCommentLength) {
      return;
    }
    this.commentError = false;
    this.loading = true;
    const payload = {
      id: this.commentId,
      text: this.comment,
      mentions: this.mentionsArray.map((item) => item._id),
    };
    const action = this.isSubcomment ? 'editSubcomment' : 'editComment';
    vxm.post[action](payload)
      .then((res: {mentions: {username: string; _id: string}[] | undefined}) => {
        this.$emit('commentUpdated', {
          id: this.commentId,
          text: this.comment,
          ...(res.mentions && {mentions: res.mentions}),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeModal(): void {
    this.$emit('closeEditModal');
  }
  onCommentDeletion(): void {
    this.loading = true;
    if (this.isSubcomment) {
      this.deleteSubcomment();
    } else {
      this.deleteComment();
    }
  }
  deleteComment(): void {
    vxm.post
      .deleteComment(this.commentId)
      .then(() => {
        this.$emit('commentDeleted');
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleteSubcomment(): void {
    vxm.post
      .deleteSubcomment(this.commentId)
      .then(() => {
        this.$emit('commentDeleted');
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
