












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'BhSelect'})
export default class BhSelect extends Vue {
  isActive = false;
  isObjectArray = false;
  @Prop(String) readonly value!: string;
  @Prop(Array) readonly options!: Array<string | {label: string; value: string}>;
  @Prop({
    default() {
      return {label: 'label', value: 'value'};
    },
  })
  @Prop(String)
  readonly placeholder!: string;
  @Prop(String) readonly errorMsg!: string;
  @Prop(Boolean) readonly error!: boolean;

  mounted() {
    const type = typeof this.options[0];
    this.isObjectArray = type !== 'string';
  }

  get selectVal() {
    return this.value;
  }
  set selectVal(value) {
    this.$emit('input', value);
  }
  get selectColor() {
    if (!this.value) {
      return {
        color: 'var(--alt-text-color)',
      };
    }
    return null;
  }
}
